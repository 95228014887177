@import url('https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap');

.App {
  text-align: center;
  font-family: 'Permanent Marker', cursive;
  font-size: 20px;
  background: #101112;
  color: white;
  position: relative;
  min-height: 100vh;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#content-wrap {
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.btn-outline-secondary{
  font-family: Roobert,Helvetica Neue,Helvetica,Arial,sans-serif!important;
  color: #ffffff !important;
  background-color: #772ce8 !important;
  border-color: #772ce8 !important;
}
.btn-outline-secondary:hover{
  color: #a970ff !important;
  border-color: #a970ff !important;
  background-color:#ffffff !important;

}
.btn-outline-secondary:focus{
  box-shadow: 0 0 0 0.2rem #772ce8;
}
Button a{
  color: #a970ff !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #772ce8 !important;
}
#navBrand{
  cursor: pointer;
}
.next {

  color: white;
  background-color: #772ce8;
  cursor: pointer;
}

/*.round {*/
/*  border-radius: 50%;*/
/*}*/
a {
  /*display: inline-block;*/
  text-decoration: none;

  padding: 8px 16px;
}

/*a:hover {*/
/*  background-color: #772ce8;*/
/*  color: black;*/
/*}*/
.next:hover {
  background-color: #772ce8;
  color: black;
}