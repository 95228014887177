@media (min-width: 992px) {

    div h1{
        font-size: 40px;
    }
    iframe {
        width: 1080px;
        height: 600px;
    }
    img {
        width: 1080px;
        height: 600px;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    div h1{
        font-size: 32px;
    }
    iframe {
        width: 640px;
        height: 360px;
    }
    img {
        width: 640px;
        height: 360px;
    }
}

@media (min-width: 481px) and (max-width: 766px) {
    div h1{
        font-size: 28px;
    }

    div iframe {
        width: 100%;
        height: 380px;
    }
    img {
        width: 100%;
        height: 380px;
    }
}

@media (max-width: 480px) {
    div h1{
        font-size: 22px;
    }
    div iframe {
        width: 100%;
        height: 300px;
    }
    img {
        width: 100%;
        height: 300px;
    }
}